import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import BannerUpcoming from '../components/BannerUpcoming';
import { useStaticQuery, graphql } from 'gatsby';

const Upcoming = () => {
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const data = useStaticQuery(graphql`
    query {
      allUpcomingPublicationsCsv {
        nodes {
          Year
          Month
          Product
          Closing_Date
          Ad_Copy_Deadline
          Issue
          _Issue_Date
        }
      }
    }
  `);

  const allData = data.allUpcomingPublicationsCsv.nodes;

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(allData);
  }, [allData]);

  const handleSort = (column) => {
    if (sortedColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedColumn(column);
      setSortDirection('asc');
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  useEffect(() => {
    const filteredData = allData.filter((row) => {
      const {
        Product,
        Issue,
        Month,
        Closing_Date,
        Ad_Copy_Deadline,
        _Issue_Date,
        Year,
      } = row;
      const searchLowerCase = searchQuery.toLowerCase();
      return (
        Product.toLowerCase().includes(searchLowerCase) ||
        Issue.toLowerCase().includes(searchLowerCase) ||
        Month.toLowerCase().includes(searchLowerCase) ||
        Closing_Date.toString().includes(searchLowerCase) ||
        Ad_Copy_Deadline.toString().includes(searchLowerCase) ||
        _Issue_Date.toString().includes(searchLowerCase) ||
        Year.toString().includes(searchLowerCase)
      );
    });
    setFilteredData(filteredData);
  }, [searchQuery, allData]);

  const sortedData = [...filteredData].sort((a, b) => {
    const aValue = a[sortedColumn];
    const bValue = b[sortedColumn];
    if (aValue < bValue) {
      return sortDirection === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const resetSearch = () => {
    setSearchQuery('');
    setSortedColumn(null);
    setSortDirection(null);
  };

  return (
    <Layout>
      <Helmet>
        <title>Upcoming Publications - Mills Publishing Inc.</title>
        <meta
          name="description"
          content="Upcoming publications, dates, and deadlines."
        />
      </Helmet>

      <BannerUpcoming />

      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <header className="major">
              <h2>Upcoming Deadlines</h2>
            </header>
            <div className="box">
              <div className="table-wrapper">
                <h3>Search or Click a Column to Sort</h3>
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <br />
                <button onClick={resetSearch}>Reset</button>
                <br />
                <br />
                <table>
                  <thead>
                    <tr>
                      <th onClick={() => handleSort('Product')}>
                        Publication
                        {sortedColumn === 'Product' && (
                          <span>{sortDirection === 'asc' ? '⬆' : '⬇'}</span>
                        )}
                      </th>
                      <th onClick={() => handleSort('Issue')}>
                        Issue
                        {sortedColumn === 'Issue' && (
                          <span>{sortDirection === 'asc' ? '⬆' : '⬇'}</span>
                        )}
                      </th>
                      <th onClick={() => handleSort('Month')}>
                        Issue Month
                        {sortedColumn === 'Month' && (
                          <span>{sortDirection === 'asc' ? '⬆' : '⬇'}</span>
                        )}
                      </th>
                      <th onClick={() => handleSort('Closing_Date')}>
                        Closing Date
                        {sortedColumn === 'Closing_Date' && (
                          <span>{sortDirection === 'asc' ? '⬆' : '⬇'}</span>
                        )}
                      </th>
                      <th onClick={() => handleSort('Ad_Copy_Deadline')}>
                        Ad Copy Deadline
                        {sortedColumn === 'Ad_Copy_Deadline' && (
                          <span>{sortDirection === 'asc' ? '⬆' : '⬇'}</span>
                        )}
                      </th>
                      <th onClick={() => handleSort('_Issue_Date')}>
                        Issue Date
                        {sortedColumn === '_Issue_Date' && (
                          <span>{sortDirection === 'asc' ? '⬆' : '⬇'}</span>
                        )}
                      </th>
                      <th onClick={() => handleSort('Year')}>
                        Year
                        {sortedColumn === 'Year' && (
                          <span>{sortDirection === 'asc' ? '⬆' : '⬇'}</span>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedData.map((row, index) => (
                      <tr key={index}>
                        <td>{row.Product}</td>
                        <td>{row.Issue}</td>
                        <td>{row.Month}</td>
                        <td>{row.Closing_Date}</td>
                        <td>{row.Ad_Copy_Deadline}</td>
                        <td>{row._Issue_Date}</td>
                        <td>{row.Year}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Upcoming;
